import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import abductionIllustration from '../images/abduction-illustration.svg';

import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

function NotFoundPage() {
	return (
		<Layout>
			<SEO pageTitle="404: Not found" dontIndex />
			<div>
				<img
					src={abductionIllustration}
					className="block w-1/2 mx-auto"
					alt="Ghost getting abducted by aliens"
				/>
				<h2 className="inline-block p-3 my-8 text-2xl font-bold bg-yellow-400">
					Looks like this page is a ghost that got abducted by aliens...
				</h2>
			</div>
		</Layout>
	);
}

export default withPrismicUnpublishedPreview(NotFoundPage);
